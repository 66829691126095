<template>
  <div class="containerBox">
    <Helper></Helper>
    <!--顶部导航栏-->
    <Navbar2 class="navHeader" @searchKey="getKey" navBg="E0EAFF"></Navbar2>
    <div class="uploadBox">
      <div class="headBox">
        <div @click="changeSys(1)" :class="[params.system == 1 ? 'winBox1' : 'winBox']"><img src="../../assets/image/freeSoftware/win1.png" v-if="params.system === 1"><img src="../../assets/image/freeSoftware/win.png" v-else>Win版</div>
        <div @click="changeSys(2)" :class="[params.system == 2 ? 'macBox1' : 'macBox']"><img src="../../assets/image/freeSoftware/mac1.png" v-if="params.system === 2"><img src="../../assets/image/freeSoftware/mac.png" v-else>Mac版</div>
      </div>
      <el-skeleton
          style="width: 100%"
          :loading="loading"
          animated
          :count="12"
      >
        <div slot="template">
          <el-skeleton-item
              variant="image"
              class="itemBox"
          />
        </div>
      <div class="contentBox" v-if="list.length > 0">
        <div class="itemBox" v-for="item in list" :key="item.id">
          <div class="topBox">
            <img :src="item.icon" alt="">
            <div>{{item.title}}</div>
          </div>
          <div class="middleBox" v-if="item.baiduCode">
            提取码：{{item.baiduCode || '无'}}
          </div>
          <div class="topBox">
            <el-button  @click="toDetail(item.id)" type="text"><i class="el-icon-download el-icon--right"></i> 立即下载</el-button>
          </div>
        </div>
        <template v-if="list.length%4 !== 0">
          <div class="itemBoxNull" v-for="item in (4 - list.length%4)" :key="item.id">
          </div>
        </template>
      </div>
      <div v-else>
        <el-empty description="没有内容哦"></el-empty>
      </div>
      </el-skeleton>
    </div>
    <div class="pageNumberBox">
      <el-pagination
          v-if="total > 0"
          background
          @current-change="setPage"
          layout="prev, pager, next"
          :current-page.sync="params.page"
          :total="total"
          :page-size="12"
      ></el-pagination>
    </div>
    <Footer style="z-index:12"></Footer>
  </div>
</template>
<script>
import Navbar2 from "./componts/Navbar2";
import {
  Footer
} from "../../layout/componts";
import { getList, getListById } from "../../Api";
import Helper from "./componts/helper"
import {mapGetters} from "vuex";
export default {
  name: "SoftwareListIndex",
  components: {
    Navbar2,
    Footer,
    Helper
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    // window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return {
      loading: false,
      paramsById: {
        child: '',
        system: 1,
        page: 1
      },
      params:{
        key: '',
        system: 1,
        page: 1
      },
      total: 0,
      list: []
    }
  },
  created() {
    if(this.$route.query.child){
      this.paramsById.child = this.$route.query.child
      this.initDataById()
    }else{
      this.params.key = this.$route.query.key;
      this.initData();
    }
  },
  methods: {
    toDetail(id){
      // console.log(id)
      if(!this.isLogin){
        this.$login()
        return
      }
      const {href} = this.$router.resolve({ path: "/software/softwareDetail", query: {id:id } });
      window.open(href, '_blank')
      // this.$router.push({ path: "/software/softwareDetail", query: {id:id } });
    },
    getKey(val){
      this.params.key = val
      this.initData();
    },
    setPage(val){
      this.paramsById.page = val
      this.params.page = val
      if(this.$route.query.child){
        this.initDataById()
      }else{
        this.initData()
      }
    },
    changeSys(val){
      this.paramsById.system = val
      this.params.system = val
      this.params.page = 1
      this.paramsById.page = 1
      if(this.$route.query.child){
        this.initDataById()
      }
      else{
        this.initData()
      }
    },
    initDataById(){
      this.loading = true
      getListById(this.paramsById).then(res=>{
        this.paramsById.child = this.$route.query.child
        if(res.status === '200'){
          this.list = res.data.data
          this.total = res.data.total
        }
        this.loading = false
      })
    },
    initData(){
      this.loading = true
      getList(this.params).then(res=>{
        this.params.key = this.$route.query.key
        if(res.status === '200'){
          this.list = res.data.data
          this.total = res.data.total
        }
        this.loading = false
      })
    }
  }
};
</script>
<style scoped>
.containerBox{
  background-image: linear-gradient(#E0EAFF, #FAFCFF);
  min-width: 1440px;
}
>>> .el-button ,.el-button--text{
  border: 1px solid rgba(0, 100, 250, 1);
  padding: 6px
}
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.uploadBox{
  width:14.4rem;
  margin:auto;
  border-radius:0.08rem;
  height:7.9rem
}
.navHeader{
  position: sticky;
  top: 0px
}
.headBox{
  display: flex;
  width: 3rem;
  align-items: center;
  margin: auto;
  margin-bottom:0.6rem;
  margin-top:0.82rem
}
.headBox img{
  height:0.3rem;
  width: 0.3rem;
}
.winBox,.macBox{
  width:15.8rem;
  height:0.48rem;
  background:white;
  text-align: center;
  line-height: 0.48rem;
  margin:0.12rem;
  border-radius: 0.04rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.winBox1{
  background: rgba(0, 100, 250, 1) !important;
  width:15.8rem;
  height:0.48rem;
  text-align: center;
  line-height: 0.48rem;
  margin:0.12rem;
  border-radius: 0.04rem;
  cursor: pointer;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.macBox1{
  background: rgba(0, 100, 250, 1) !important;
  width:15.8rem;
  height:0.48rem;
  text-align: center;
  line-height: 0.48rem;
  margin:0.12rem;
  border-radius: 0.04rem;
  cursor: pointer;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .contentBox{
  width:14.4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}
/*::v-deep .contentBox::after {*/
/*  content: "";*/
/*  width: 345px;*/
/*}*/
.itemBoxNull{
  width:3.45rem;
  height:2.01rem;
}
::v-deep .itemBox{
  width:3.45rem;
  height:2.01rem;
  background: white;
  margin-bottom: 0.12rem;
  border-radius: 0.08rem
}
.pageNumberBox{
  text-align: center;
  /*margin-top:0.22rem;*/
  margin-bottom:0.22rem
}
.topBox{
  display: flex;
  align-items: center;
  padding: 0.24rem;
}
.topBox>img{
  width:0.56rem;height:0.56rem
}
.topBox>div{
  width:1.8rem;
  text-wrap: normal;
  margin-left: 0.16rem;
  word-break:break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.middleBox{
  margin-left:0.24rem;
  height: 0.21rem;
  color: rgba(128, 128, 128, 1);
  font-size: 0.14rem;
  font-weight: 400;
}
</style>